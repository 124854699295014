import React, { useState, useEffect } from 'react';
import LogAction from '../Utilities/LogAction';
import TopMenuNavigationLogo from '../TopMenuNavigation/TopMenuNavigationLogo';
import TopMenuNavigationContact from '../TopMenuNavigation/TopMenuNavigationContact';
import { tucsonForm } from '../../../data/formsData';
import Form from '../Form/Form';
import SectionHeader from '../Neutral/SectionHeader';
import ContactPageInfo from '../ContactPage/ContactPageInfo';
import IONIQ5_Front from '../../../assets/ioniq5_driving.jpeg';
import ColorConfigurator from './ColorConfigurator';
import Carousel from '../Neutral/Carousel';
import PriceList from './PriceList';
import ScrollToTopButton from '../Utilities/ScrollToTopBotton'; // Import the ScrollToTopButton component
import ContactPagePhone from '../ContactPage/ContactPagePhone';

// Function to import all images from a directory
function importAll(r) {
    return r.keys().map(r);
}

// Import all images from the assets/Modele/Tucson directory
const images = importAll(require.context('../../../assets/Modele/Ioniq 5', false, /\.(png|jpe?g|svg)$/));

const initialFormState = {
    name: '',
    email: '',
    phone: '',
    marketing: false,
    marketing_sms: false,
    marketing_phone: false,
    marketing_email: false,
};

function ModelIoniq5Page() {
    const [formData, setFormData] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        LogAction('User entered the IONIQ5 Page');

        return () => {
            LogAction('User left the Ioniq5 Page');
        };
    }, []);

    const validate = () => {
        const newErrors = {};
        const { marketing_phone, marketing_email } = formData;

        if (!marketing_phone && !marketing_email) {
            newErrors.contact = "At least one contact method (marketing phone or marketing email) is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleRestart = () => {
        setIsSubmitted(false);
        setFormData(initialFormState);
    };

    const scrollToContactForm = () => {
        const contactFormSection = document.getElementById('contact-form-section');
        if (contactFormSection) {
            contactFormSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (isSubmitted) {
        return (
            <div className="form-summary">
                <p>Dziękujemy za przesłanie formularza. Nasz Doradca skontaktuje się z Tobą w przeciągu kilku godzin.</p>
                <button onClick={handleRestart}>Wróć do formularza</button>
            </div>
        );
    }

    return (
        <>
            <header className='topmenunavigation animation-container'>
                <TopMenuNavigationLogo />
                <TopMenuNavigationContact />
            </header>
            <div className='grid-page'>
                <div className='grid-page-1 model-baner-i5 animation-container ioniq-fit'>
                    <img src={IONIQ5_Front} alt="Ioniq 5" />
                    <h1>IONIQ 5</h1>
                    <h2>Teraz w racie 1850 zł netto z dotacją jako wpłata własna</h2>
                </div>
                <SectionHeader title='Kup w okazyjnym finansowaniu' />
                <div className="grid-page-2-3 article-two-third ">
                    <div className="grid-page-2-3-sub grid-color-white model-configuration animation-container">
                        
                        <h2>Hyundai Ioniq 5 z rocznika 2023</h2>
                        <p>Finansowanie leasing operacyjny Mleasing z nieobowiązkowym wykupem.</p>
                        
                        <div className='finance'>
                            <div className='finance-downpayment'>
                                <span>Dotacja 27.000 jako wpłata własna</span>
                                <p></p>
                                <p></p>
                            </div>
                            <div className='finance-monthlypayment'>
                                <p></p>
                                <span>1 850 netto rata miesięczna</span>
                                <p></p>
                            </div>
                            <div className='finance-period'>
                                <span>24 miesiące</span>
                                <p></p>
                                <span>Nieobowiązakowy wykup</span>
                            </div>
                            <div className='finance-period'>
                                <p></p>
                                <span>Pakiet serwisowy w cenie</span>
                                <p></p>
                            </div>

                        </div>
                        
                    </div>
                    
                    <div className="grid-page-2-3-sub article-content grid-color-white animation-container">
                        <Form 
                            formData={tucsonForm} 
                            formDataValues={formData}
                            setFormData={setFormData}
                            setErrors={setErrors}
                            setIsSubmitted={setIsSubmitted}
                            setIsLoading={setIsLoading}
                            errors={errors}
                            isLoading={isLoading}
                        />
                        <p>Wypełniając powyzszy formularz, wyrazasz zgodę na skontaktowanie się z Tobą. Twoje dane nie zostaną wykorzystane do marketingu przyszłego. <a href='/polityka-prywatnosci'>Kliknij tu po więcej informacji</a></p>
                    </div>
                </div>
                <SectionHeader title='Dofinansowanie jako wpłata własna' />
                <div className="grid-page-1 article-content grid-color-white animation-container model-i5">
                    <div>
                        <h3>Zostały ostatnie sztuki</h3>
                    </div>
                    <div className="table-container">
                        <table className="model-table-i5">
                            <thead>
                                <tr>
                                    <th>Model</th>
                                    <th>Kolor</th>
                                    <th>Wpłata własna <br/>brutto</th>
                                    <th>Okres</th>
                                    <th>Rata miesięczna netto</th>
                                    <th>Zarezerwuj</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>IONIQ 5 PY2023 77 kwh 2WD UNIQ + SUN</td>
                                    <td>Shooting Star Matowy / Dark Pebble Grey & Dove Grey </td>
                                    <td className="align-right">27 000 zł</td>
                                    <td>24 miesiące</td>
                                    <td className="align-right special-price">1 851,36 zł</td>
                                    <td><button onClick={scrollToContactForm}>Rezerwuj</button></td>
                                </tr>
                                <tr>
                                    <td>IONIQ 5 PY2023 77 kwh 2WD UNIQ + SUN</td>
                                    <td>Abyss Black / Dark Teal & Dove Grey</td>
                                    <td className="align-right">27 000 zł</td>
                                    <td>24 miesiące</td>
                                    <td className="align-right special-price">1 851,36 zł</td>
                                    <td><button onClick={scrollToContactForm}>Rezerwuj</button></td>
                                </tr>
                                <tr>
                                    <td>IONIQ 5 PY2023 77 kwh 4WD UNIQ + SUN + TECH</td>
                                    <td>Atlas White Matowy / Dark Teal & Dove Grey -</td>
                                    <td className="align-right">27 000 zł</td>
                                    <td>24 miesiące</td>
                                    <td className="align-right special-price">2 702,19 zł</td>
                                    <td><button onClick={scrollToContactForm}>Rezerwuj</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <SectionHeader title='Cenniki' />
                
                <div className='grid-page-1 grid-color-white animation-container'>
                    <PriceList modelName='ioniq5' />
                </div>
                <SectionHeader title='Przekonaj się sam' />
                <div className='model-photos animation-container'>
                    <Carousel interval={5000} height='600px' data={images.map(image => ({ image }))} />
                </div>
                
                <SectionHeader title='Odwiedź nasz salon' />
                <div className='grid-page-1 animation-container grid-color-white'>
                    <ContactPageInfo />
                </div>
                <SectionHeader title='Skontaktuj się z nami' />
                <div id="contact-form-section" className='grid-page-2 animation-container grid-color-white'>
                    <div className=''>
                        <Form 
                            formData={tucsonForm} 
                            formDataValues={formData}
                            setFormData={setFormData}
                            setErrors={setErrors}
                            setIsSubmitted={setIsSubmitted}
                            setIsLoading={setIsLoading}
                            errors={errors}
                            isLoading={isLoading}
                        />
                        <p>Wypełniając powyzszy formularz, wyrazasz zgodę na skontaktowanie się z Tobą. Twoje dane nie zostaną wykorzystane do marketingu przyszłego. 
                            <a href='/polityka-prywatnosci'>Kliknij tu po więcej informacji</a>
                        </p>
                        
                    </div>
                    <div>
                        <ContactPagePhone />
                    </div>
                </div>
            </div>
            <ScrollToTopButton /> {/* Add the ScrollToTopButton component */}
        </>
    );
}

export default ModelIoniq5Page;
