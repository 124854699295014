import React from 'react';
import LogAction from '../Utilities/LogAction';

// Function to import all files from a directory
function importAll(r) {
    return r.keys().map(r);
}


function PriceList(modelName) {

    var files = {};

    switch (modelName.modelName) {
        case 'tucson': 
            // Import all files from the assets/pliki/cenniki/Tucson directory
            files = importAll(require.context('../../../assets/pliki/cenniki/Tucson', false, /\.(pdf|docx|xlsx)$/));
            break;
        case 'ioniq5':
            // Import all files from the assets/pliki/cenniki/Ioniq5 directory
            files = importAll(require.context('../../../assets/pliki/cenniki/Ioniq5', false, /\.(pdf|docx|xlsx)$/));
            break;
        default:
            files = importAll(require.context('../../../assets/pliki/cenniki/Tucson', false, /\.(pdf|docx|xlsx)$/));
            break;
    }

    return (
        <div className="price-list">
            <ul>
                {files.map((file, index) => {
                    const fileName = file.split('/').pop().split('.')[0];
                    return (
                        <li key={index}>
                            <a href={file} download onClick={() => {
                                LogAction(`/${modelName.modelName}, download ${fileName}`);
                            }}>
                                <div className="file-item">
                                    <span className="file-icon">📄</span>
                                    <span className="file-name">{fileName}</span>
                                </div>
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default PriceList;
